import './App.css'
import ReactTypingEffect from 'react-typing-effect';
import { AlternateEmail, Diamond, GitHub, LinkedIn } from '@mui/icons-material';

function App() {
  return (
    <div className="App">
      <div className="content">
        <div className="header">
            <span className="first-name">Aliaksei</span> <span className="last-name">Matskevich</span>
        </div>
        <div className="typewrite">
          <ReactTypingEffect
            text={[
              "Full Stack Software Engineer",
              "Based in Munich",
              "Building the Web ❤️"
            ]}
            eraseSpeed={60}
            speed={60}
            eraseDelay={1000}
            typingDelay={1000}
          />
        </div>
        <div className="long-description">
          I'm a web application developer with the expertise in Frameworks / Programming Languages <span className="tech">TypeScript</span>,  <span className="tech">NodeJS</span>, <span className="tech">NestJS</span>, <span className="tech">React</span> etc.
        </div>
        <div className="contact-info">
          <span className="link">
            <a title="email" href="mailto:aliaksei.matskevich@gmx.de">
              <AlternateEmail />
            </a>
          </span>
          <span className="link">
            <a title="GitHub" href="https://github.com/alexmatskevich" target="_blank" rel="noreferrer">
              <GitHub />
            </a>
          </span>
          <span className="link">
            <a title="LinkedIn" href="https://www.linkedin.com/in/aliaksei-matskevich-583144a2/" target="_blank" rel="noreferrer">
              <LinkedIn />
            </a>
          </span>
          <span className="link">
            <a title="RubyGems" href="https://rubygems.org/profiles/Alexmatskevich" target="_blank" rel="noreferrer">
              <Diamond />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default App
